import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Loader from '../components/Loader'
import { useUserContext } from '../context/UserContext'
import Nav from './NavigationKeys'

//lazy load the different routes
const Login = React.lazy(() => import('../pages/Login'))
const Businesses = React.lazy(() => import('../pages/Businesses'))
const NotFound = React.lazy(() => import('../pages/NotFound'))

type RouteProps = {
  authenticated: boolean
  path?: string
  component?: JSX.Element
  sub?: JSX.Element
}

//Only add to this list when needed to add a new page
const myRoutes: RouteProps[] = [
  { authenticated: false, path: Nav.LOGIN, component: <Login /> },
  { authenticated: true, path: Nav.BUSINESSES, component: <Businesses /> },
  {
    authenticated: true,
    path: '*',
    component: <NotFound />,
  },
]

const NavigationStack = () => {
  const { isAuthenticated } = useUserContext()

  const [routes, setRoutes] = useState([] as React.ReactElement[])

  useEffect(() => {
    const routesList = myRoutes.map((item) => renderRoute(item))
    setRoutes(routesList)
  }, [isAuthenticated])

  const renderRoute = (item: RouteProps): React.ReactElement => {
    if (!item.authenticated) {
      return <Route path={item.path} element={item.component} />
    }
    if (isAuthenticated) {
      if (item.sub) {
        return item.sub
      } else {
        return <Route path={item.path} element={item.component} />
      }
    } else {
      return <Route path={item.path} element={<Navigate to={Nav.LOGIN} />} />
    }
  }
  return (
    <React.Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Routes>{routes}</Routes>
      </BrowserRouter>
    </React.Suspense>
  )
}

export default NavigationStack
