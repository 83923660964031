import { ReactElement } from 'react'
import { Location } from './Common'

export interface GalleryItemProps {
  RightIcon?: (item: GalleryItem) => React.ReactElement | null
  onPressItem: (item: GalleryItem) => void
  onRemoveUploadingItem?: (item: GalleryItem) => void
  onRetryUpload?: (item: GalleryItem) => void
}

export interface GallerySectionProps {
  key: string
  RightIcon: (item: GalleryItem) => React.ReactElement | null
  onPressItem: (item: GalleryItem) => void
  onRemoveUploadingItem?: (item: GalleryItem) => void
  onRetryUpload?: (item: GalleryItem) => void
}

export enum UploadCategory {
  document = 'document',
  photo = 'photo',
  video = 'video',
  audio = 'audio',
}

export enum GalleyType {
  video = 'video',
  photo = 'photo',
  audio = 'audio',
  docs = 'docs',
  clips = 'clips',
}
export enum GalleryTab {
  media = 'media',
  docs = 'docs',
  clips = 'clips',
}

export enum UploadStatus {
  uploading = 'uploading',
  uploadFailed = 'uploadFailed',
  uploadingToGallery = 'uploadingToGallery',
  uploaded = 'uploaded',
}

export interface UploadItem {
  recordKey: string
  galleryType: GalleyType
  uploadCategory?: UploadCategory
  uploadedByUserId: string
  objectId: string
  uploadStatus?: UploadStatus
  uploadingUrl?: string
  uploadingStatusValue?: number
  fileName?: string
  file?: File
  tab?: GalleryTab
  gtUpdatedTime: string
  gtCreatedTime: string
}

export interface GalleryItem extends UploadItem {
  index?: number
}

export type GalleyTabItems = {
  title: string
  data: { key: string; data: GalleryItem[] }[]
}

export type GalleryTabProps = {
  items: GalleyTabItems[]
  fetchNextPage: () => void
  hasNextPage: boolean | undefined
  isFetchingNextPage: boolean
  renderFooter: ReactElement
}

/* Media and related Types */

type MediaMetaData = {
  gtUpdatedTime: string
  extension: string
  contentType: string
  gtCreatedTime: string
  origin: string
  businessId: string
  mediaCategory: string
  uploadedByUserId: string
  contentLength: number
  fileName: string
  mediaSource: string
  uploadedByEffectiveUserId: string
  originId: string
  threadId: string
}

export interface Media extends GalleryItem {
  sourceId: string
  mediaType: string
  userId: string
  imageType: string
  source: string
  sourceStatus: string
  effectiveUserId: string
  threadId: string
  businessId: string
  messageId: string
  url: string
  origin?: string
  mediaCategory: string
  originId: string
  mediaSource: string
  metaData?: MediaMetaData
  extension: string
  mimeType: string
}

/* Clip and related Types */
enum SlideType {
  photo,
  video,
}
export type Slide = {
  slideSequence: number
  slideId: string
  thumbnailKey: string
  slideKey: string
  slideType: SlideType
  clipId: string
  businessLogo?: string
  businessName?: string
}

export enum EntityType {
  business = 'business',
  user = 'user',
}
export type HighlightedEntity = {
  [id: string]: {
    entityType: EntityType
  }
}

enum TaggedStatus {
  approved = 'approved',
  rejected = 'rejected',
}
export interface TaggedEntity {
  taggedStatus: TaggedStatus
  tagEntityType: EntityType
}
export interface TaggedEntityBusiness extends TaggedEntity {
  businessName: string
  handle: string
  businessId: string
  businessLogo: string
}
export interface TaggedEntityUser extends TaggedEntity {
  phoneNumber: string
  profilePic: string
  userId: string
  effectiveUserId: string
  businessId?: string
  firstName: string
  email: string
  lastName: string
}
export type TaggedEntities = {
  [key: string]: TaggedEntityBusiness | TaggedEntityUser
}

export interface Clip extends GalleryItem {
  origin: string
  clipTitle: string
  taggedServices: string[]
  location: Location
  coverImage: string
  addToHighlights: boolean
  slides: Slide[]
  admins: string[]
  clipId: string
  gtUpdatedTime: string
  highlightedEntities: { [key: string]: HighlightedEntity }
  businessHandle: string
  threadId: string
  postedBy: string
  clipDescription: string
  taggedEntities: TaggedEntities
  businessId: string
  businessName: string
  businessLogo: string
  clipUrl: string
  sequence: number
}

/* End: Clip and related Types */

/* Document type */
export interface Document extends GalleryItem {
  effectiveUserId: string
  fileName: string
  imageType: string
  mediaType: string
  messageId: string
  origin: string
  source: string
  sourceId: string
  sourceStatus: string
  threadId: string
}
/* End:Document type */

export enum ImageUploadFolderType {
  IMAGE = 'image',
  PROFILE = 'profile',
}
