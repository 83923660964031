import { Auth } from 'aws-amplify'

const awsConfig = {
  Auth: {
    identityPoolId: process.env.COGNITO_IDENTITY_POOL_ID,

    region: process.env.COGNITO_POOL_ID?.split('_')[0],

    userPoolId: process.env.COGNITO_POOL_ID,

    userPoolWebClientId: process.env.CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'adminApi',
        endpoint: process.env.DOMAIN_API_URL,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          }
        },
      },
    ],
  },
  Storage: {
    bucket: process.env.MEDIA_S3_BUCKET, //REQUIRED -  Amazon S3 bucket
    region: process.env.REGION, //OPTIONAL -  Amazon service region
  },
}

export default {
  ...awsConfig,
}
