import axios from 'axios'
import AuthUtil from '../common/AuthUtil'
import Constants from './Constants'
import { MediaNotAvailableError, MediaTypeNotSupportedError } from './Errors'
global.Buffer = global.Buffer || require('buffer').Buffer

export const getAxios = (withAuthentication = true) => {
  const axiosInstance = axios.create({
    baseURL: process.env.DOMAIN_API_URL_2,
    timeout: process.env.DEFAULT_TIMEOUT
      ? parseInt(process.env.DEFAULT_TIMEOUT)
      : 0,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': process.env.API_KEY || '',
    },
  })

  axiosInstance.interceptors.request.use(async (request) => {
    if (withAuthentication) {
      const session = await AuthUtil.getCurrentSession()
      const token = session.getIdToken().getJwtToken()
      request.headers.Authorization = `Bearer ${token || ''}`

      // const activeRole = await StorageUtil.getActiveRole()
      // if (activeRole?.['org-id']) {
      //   request.headers['org-id'] = activeRole?.['org-id']
      // }
    }

    return request
  })

  axiosInstance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  return axiosInstance
}

export const fetchMedia = async (key: string, size?: string) => {
  const session = await AuthUtil.getCurrentSession()
  const token = session.getAccessToken().getJwtToken()

  let uri = `${process.env.MEDIA_URL}\\${key}`
  if (size) {
    uri += `?size=${size}`
  }
  return axios
    .get(uri, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'arraybuffer',
    })
    .then((response) => {
      if (response?.data) {
        const imageBase64Str = Buffer.from(response.data, 'binary').toString(
          'base64'
        )
        const contentType =
          response.headers['content-type'] || Constants.MIME_TYPES.PNG
        if (
          contentType === Constants.MIME_TYPES.HEIC ||
          contentType === Constants.MIME_TYPES.HEIF
        ) {
          throw new MediaTypeNotSupportedError()
        }
        return `data:${contentType};base64,${imageBase64Str}`
      }
    })
    .catch((error) => {
      if (error instanceof MediaTypeNotSupportedError) {
        throw error
      } else {
        throw new MediaNotAvailableError()
      }
    })
}
