import Constants from './Constants'

export class MediaTypeNotSupportedError extends Error {
  constructor() {
    const name = Constants.ERRORS.MEDIA_TYPE_NOT_SUPPORTED_ERROR
    super(name)
    this.name = name
  }
}

export class MediaNotAvailableError extends Error {
  constructor() {
    const name = Constants.ERRORS.MEDIA_NOT_AVAILABLE_ERROR
    super(name)
    this.name = name
  }
}
