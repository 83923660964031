import { API } from 'aws-amplify'

class AdminAPI {
  apiName = 'adminApi'
  path = '/admin'
  initState = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }

  getQuery = (filter, params, page_size, key = null) => {
    return new Promise(async (resolve, reject) => {
      let body = {
        filter,
        params: JSON.parse(params),
        page_size,
      }

      if (key) {
        body = {
          ...body,
          start_key: key,
        }
      }

      try {
        const response = await API.post(this.apiName, this.path, {
          ...this.initState,
          body,
        })

        if (response.statusCode === 200) {
          resolve(JSON.parse(response.body))
        } else {
          reject(response.statusCode)
        }
      } catch (error) {
        reject(error)
      }
    })
  }

  storeQuery = (filter, item) => {
    return new Promise(async (resolve, reject) => {
      const body = {
        filter,
        params: JSON.parse(item),
      }

      try {
        const response = await API.post(this.apiName, this.path, {
          ...this.initState,
          body,
        })
        if (response.statusCode === 200) {
          resolve(true)
        } else {
          reject(response.statusCode)
        }
      } catch (error) {
        reject(error)
      }
    })
  }

  trashQuery = (filter, item) => {
    return new Promise(async (resolve, reject) => {
      const body = {
        filter,
        params: item,
      }

      try {
        const response = await API.post(this.apiName, this.path, {
          ...this.initState,
          body,
        })
        if (response.statusCode === 200) {
          resolve(true)
        } else {
          reject(response.statusCode)
        }
      } catch (error) {
        reject(error)
      }
    })
  }

  reindexingQuery = (filter, params, page_size, start_key) => {
    return new Promise(async (resolve, reject) => {
      const body = {
        filter,
        params,
        page_size,
        start_key,
      }

      try {
        const response = await API.post(this.apiName, this.path, {
          ...this.initState,
          body,
        })

        if (response.statusCode === 200) {
          if (response.body) {
            // This validation added until message reindex backend change
            resolve(JSON.parse(response.body))
          }
        } else {
          reject(response.statusCode)
        }
      } catch (error) {
        reject(error)
      }
    })
  }
}

export default AdminAPI
