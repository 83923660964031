export default {
  BUSINESSES: 'businesses',
  BUSINESS: 'business',
  BUSINESS_SEARCH_RESULTS: 'businessSearchResults',
  BUSINESS_CLIPS: 'businessClips',
  TAGGED_CLIPS: 'taggedClips',
  CLIP: 'clip',
  CLIP_HIGHLIGHTS: 'clipHighlights',
  BUSINESS_GENERATE_HANDLER: 'businessGenerateHandler',
  TRADES: 'trades',
  BUSINESS_SERVICE_AREAS: 'businessServiceAreas',
  BUSINESS_SERVICES: 'businessServices',
  BUSINESS_ABN: 'businessABN',
  LOCATIONS: 'LOCATIONS',
  BUSINESS_LICENSES: 'businessLicenses',
  VERIFY_LICENCE: 'verifyLicence',
}
