import React, { useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import NavigationStack from './navigation/NavigationStack'
import { ThemeProvider, themes } from '@gotradie/gt-components'
import { ReactQueryDevtools } from 'react-query/devtools'
import {
  useActiveUserId,
  UserContextProvider,
  useUserContext,
} from './context/UserContext'
import ContentWrapper from './layout/ContentWrapper'
import { AuthUtil } from './common'
import { Amplify } from 'aws-amplify'
import { AuthConfig } from './common'
import { activateRemoteConfig, remoteConfig } from './remoteConfig'

function Content() {
  const activeUserId = useActiveUserId()
  const { activeTheme, isAuthenticated, setAuthenticated } = useUserContext()

  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    activateRemoteConfig()
    AuthUtil.getCurrentSession()
      .then((session) => {
        setAuthenticated(session && session.isValid())
      })
      .catch(() => {
        setAuthenticated(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    if (!activeUserId) {
      return
    }
  }, [isAuthenticated, activeUserId])

  return (
    <ThemeProvider theme={themes[activeTheme]}>
      <ContentWrapper isLoading={isLoading} fillScreen={true}>
        <NavigationStack />
      </ContentWrapper>
    </ThemeProvider>
  )
}

export const App = () => {
  Amplify.configure(AuthConfig)
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 5 * 60 * 1000,
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <Content />
        <ReactQueryDevtools initialIsOpen={false} />
      </UserContextProvider>
    </QueryClientProvider>
  )
}
