import React from 'react'
import LinearGradient from 'react-native-linear-gradient'
import { AppColors } from '@gotradie/gt-components'
import { View, ScrollView, SafeAreaView, StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  gradientContainerStyle: {
    height: '100vh',
  },
  safeAreaViewStyle: {
    flex: 1,
  },
})

type ContentProps = {
  isLoading?: boolean
  fillScreen?: boolean
  children: JSX.Element[] | JSX.Element
}

const ContentWrapper = ({
  isLoading = true,
  fillScreen = false,
  children,
}: ContentProps) => {
  return (
    <LinearGradient
      style={styles.gradientContainerStyle}
      colors={[AppColors.gradient.color1, AppColors.gradient.color2]}
    >
      <SafeAreaView style={styles.safeAreaViewStyle}>
        <ScrollView
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={true}
        >
          {!isLoading && (
            <View style={{ height: fillScreen ? '100vh' : '100%' }}>
              {children}
            </View>
          )}
        </ScrollView>
      </SafeAreaView>
    </LinearGradient>
  )
}

export default ContentWrapper
