import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'
import AuthConfig from './AuthConfig'

function getCurrentSession(): Promise<CognitoUserSession> {
  // Amplify.configure(AuthConfig)
  Auth.configure(AuthConfig)
  return Auth.currentSession()
}

async function logoutSession() {
  // Amplify.configure(AuthConfig)
  Auth.configure(AuthConfig)
  await Auth.signOut({ global: true })
}

function loginUser(username: string, password: string): Promise<any> {
  // Amplify.configure(AuthConfig)
  Auth.configure(AuthConfig)
  return Auth.signIn(username, password)
}

export default {
  getCurrentSession,
  logoutSession,
  loginUser,
}
